<template>
    <div class="container-fluid">
        <section class="content-header">
            <div class="row mb-2">
                <h3>{{ $t('general.listadodeempresas') }}</h3>
            </div>
        </section>
        <section class="content">
            <div class="row">
                <div class="col-md-2">
                </div>
                <div class="col-md-10">
                    <tabla-datos :columnas="columnas"  :datos="empresas" :filtros="filtros" :filtrosBuscador="filtrosBuscador" :total="total" @quieroDatos="obtenerEmpresas" />
                </div>
            </div>
        </section>
    </div>
</template>
<script>
import { PwgsApi } from '../../../services/PwgsApi';
import TablaDatos from '../../TablaDatos.vue';
export default {
    components: {
        "tabla-datos": TablaDatos,
    },
    data() {
        return {
            total: '',
            empresas: [],
            parametros: null, // query params de DataTable
            filtrosBuscador: ['nombre_empresa'], // filtros para el buscador
            filtros: { // filtros de columna
                'nombre_empresa': { value: '', matchMode: 'contains' },
                'nombre_comercial': { value: '', matchMode: 'contains' },
            },
            columnas: [
                {
                    header: 'Empresa',
                    field: 'nombre_empresa',
                    sortable: true,
                },
                {
                    header: 'Nombre Comercial',
                    field: 'nombre_comercial',
                    sortable: true,
                },
                {
                    header: 'Acciones',
                    data: null,
                    acciones: [
                        {
                            nombre: 'Editar',
                            class: 'editar',
                            tipo: 'default',
                            nombreId: 'id_empresa',
                            icono: 'far fa-edit',
                            // Debe ser arrow function para que this sea este componente
                            accion: (id) => {
                                console.log('Editar banco ' + id);
                                //this.obtenerUsuario(id);
                                this.$router.push({
                                    name: 'Empresa',
                                    params: {
                                        id:id
                                    }
                                });
                            }
                        },
                        {
                            nombre: 'Eliminar',
                            class: 'eliminar',
                            tipo: 'danger',
                            nombreId: 'id_empresa',
                            icono: 'far fa-trash-alt',
                            // Debe ser arrow function para que this sea este componente
                            accion: (id) => {
                                console.log('Eliminar empresa ' + id);
                                if (confirm('Desea eliminar la empresa')) {
                                    this.eliminar(id);
                                }
                            }
                        },
                    ]
                }
            ]
        }
    },
    methods: {
        async obtenerEmpresas(parametros) {
            /* get  modulos/pwgsapi/index.php/bancos */
            const api = new PwgsApi;
            const params = parametros;
            const datos = await api.get('empresas', params);
            this.empresas = datos.datos;
            this.columnas[0].header = this.$t("general.nombre");
            this.columnas[1].header = this.$t("general.empresa");
            this.columnas[2].header = this.$t("general.acciones");

        },
        async eliminar(id) {
            /*  modulos/pwgsapi/index.php/bancos/:id */
            console.log('eliminando empresa ' + id + '...');
            const api = new PwgsApi;
            await api.delete('empresas/' + id);
            this.obtenerEmpresas();
        },
    },
    activated() {
        this.obtenerEmpresas();
    }
}
</script>
<style>
.p-dialog {
    width: 90%;
}
</style>